body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  background-color: var(--color-paper);
  color: var(--color-print);
  text-align: center;
  line-height: 1.25;
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover {
  color: white;
}

.small {
  font-size: 0.7rem;
  opacity: 0.4;
}

:root {
  --color-paper: rgb(13, 24, 40);
  --color-print: rgb(244, 220, 180);
}

.snowflake__group {
  margin-top: 2rem;
}

.snowflake__snowflake-items {
  display: flex;
}

.snowflake__containter {
  width: 500px;
  height: 500px;
  max-width: 80vmin;
  max-height: 80vmin;

  margin: 0 auto;
  margin-top: 0.5rem;
}

.blinkA,
.blinkB {
  opacity: 0;

  animation-name: snow-showA;
  animation-duration: 4s;
}
.blinkB {
  animation-name: snow-showB;
}

.solidArm {
  fill: transparent;
  stroke: var(--color-print);

  stroke-width: 5;
}

.lineArm {
  stroke: var(--color-print);
  stroke-width: 5;
  stroke-linecap: round;
}

.lineArm--doubleLine {
  stroke-width: 2;
  stroke-linecap: round;
}

.lineArm--fanLine {
  stroke-width: 0.5;
}

.hair {
  stroke: var(--color-print);
  stroke-width: 1.2;
}

svg {
  animation: snow-spin infinite 90s linear;
}

@keyframes snow-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes snow-showA {
  from {
    opacity: 0;
  }
  5% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }

  60% {
    opacity: 1;
  }
  95% {
    opacity: 0;
  }
  to {
    opacity: 0;
  }
}
@keyframes snow-showB {
  from {
    opacity: 0;
  }
  5% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }

  60% {
    opacity: 1;
  }
  95% {
    opacity: 0;
  }
  to {
    opacity: 0;
  }
}

/* hide the iFrame of codesandbox */
iframe {
  display: none;
}
